import styled from '@emotion/styled'
import Title from 'components/Utilities/Title'
import Text from 'components/Utilities/Text'
import Image from 'gatsby-image'

export const MobilePricingContainer = styled.div`
margin-top: 40px;
padding: 0 20px;

.MuiPaper-root {
  background-color: white!important;
}


.MuiPaper-elevation1 {
  box-shadow: none!important;
}

.MuiAccordionSummary-root {
  transition: min-height .7s;
}


@media (min-width: ${props => props.theme.breakpoints.mdSmall}) {
  max-width: 800px;
  margin: 40px auto;
}


@media (min-width: ${props => props.theme.breakpoints.md}) {
  padding-right: 200px;
  padding-left: 200px;
  margin-bottom: 100px;
  padding-bottom: 0;
  max-width: unset;
}
`


export const PricingContainerMainStyled = styled.div``

export const MainTitle = styled(Title)`
  font-weight: bold;
  text-align: left;
  margin-bottom: 50px;
`

export const TitleDeco = styled.div`
  position: relative;
`

export const PricingCardContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  max-width: 1440px;

  .Skier-Pricing {
    svg {
      rect {
        fill: red;
      }
    }
  }
`

export const PriceCard = styled.div`
  background-color: ${props => props.theme.colors.white};
  min-width: 250px;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  margin-right: 50px;
  margin-left: 50px;
  padding: 20px;
  position: relative;
`

export const Header = styled(Title)`
display: inline-flex;
justify-content: space-between;
align-items: center;
width: 100%;
`

export const IconContainer = styled(Image)``

export const PricingTitle = styled(Title)`
  font-weight: 400;
`

export const Content = styled.div`
width: 100%;
padding-left: 20px;
padding-right: 20px;
display: flex;
flex-direction: column;
justify-content: space-between;

`

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const PricingFooter = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  max-width: 1440px;
  margin-top: 50px;
  padding-left: 20px;
  margin-bottom: 60px;

  p {
    opacity: .8;
    font-size: 10px;
  }
`
