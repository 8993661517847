import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import {
  OrganizationHeroContainer,
  CardImage,
  AllCardStyled,
  CardContent,
  CardContainerMain,
  OrganizationTitle,
  TitleDeco,
  Content
} from './style'
import AnimatedLine from 'components/AnimatedLine'

const OrganizationContainer = (props, ref) => {
  const { query, contentQuery } = props
  const locale = props.locale
  return (
    
      <OrganizationHeroContainer
        id={locale === 'cs-cz' ? 'organizace' : 'organization'}
        ref={ref}
      >
        <Content>
        <TitleDeco>
          <OrganizationTitle type="h3" as='h3'>
            {contentQuery.organization_title_main.text}
          </OrganizationTitle>
          <AnimatedLine className="AnimatedLine__ProjectExcerpt" />
        </TitleDeco>

        <AllCardStyled>
          
            <CardContainerMain style={{ marginLeft: '0px'}}>
              <CardImage
                fluid={
                  contentQuery.organization_card_1_image.localFile
                    .childImageSharp.fluid
                }
                imgStyle={{ objectFit: 'contain'}}
              ></CardImage>
              <CardContent type="secondary" whiteText>
                {contentQuery.organization_card_1.text}
              </CardContent>
            </CardContainerMain>
            <CardContainerMain>
              <CardImage
                fluid={
                  contentQuery.organization_card_2_image.localFile
                    .childImageSharp.fluid
                }
                imgStyle={{ objectFit: 'contain' }}
              ></CardImage>

              <CardContent type="secondary" whiteText>
                {contentQuery.organization_card_2.text}
              </CardContent>
            </CardContainerMain>
          
          
            <CardContainerMain>
              <CardImage
                fluid={
                  contentQuery.organization_card_3_image.localFile
                    .childImageSharp.fluid
                }
                imgStyle={{ objectFit: 'contain' }}
              ></CardImage>

              <CardContent type="secondary" whiteText>
                {contentQuery.organization_card_3.text}
              </CardContent>
            </CardContainerMain>
          
        </AllCardStyled>
        </Content>
      </OrganizationHeroContainer>
    
  )
}

OrganizationContainer.propTypes = {}

export default forwardRef(OrganizationContainer)
