import React from 'react'
import PropTypes from 'prop-types'
import { FlyoutMenuContainer, MenuItem, Logo } from './style'
import { Link } from 'gatsby'
import AnimatedLine from 'components/AnimatedLine'
import LocaleComponent from "./../LanguageSwitcher"

const FlyoutMenu = ({query, locale, onClose, location}) => {
  return (
    <FlyoutMenuContainer>
        {query.menu_items.map((item, index) => {
        return (
          <>
          <Link
            to={
              locale === 'cs-cz'
                ? `/${item.menu_item_uid.text}`
                : `/en/${item.menu_item_uid.text}`
            }
            onClick={onClose}
            style={{position: 'relative'}}
          >
            <MenuItem type="h5" uppercase={true}>
              {item.menu_item_label.text}
            </MenuItem>
          <AnimatedLine className="AnimatedLine__Flyout" />
          </Link>
          </>
        )
      })}
      <LocaleComponent location={location}/>
      <Logo src={'https://images.prismic.io/skischoolrokytnice/df77894a-5b40-498b-9482-8b9eb21526bd_Asset+2%403x.png?auto=compress,format'} />
    </FlyoutMenuContainer>
  )
}

FlyoutMenu.propTypes = {

}

export default FlyoutMenu
