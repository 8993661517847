import styled from '@emotion/styled'
import BackgroundImage from 'gatsby-background-image'
import Image from 'gatsby-image'
import Title from 'components/Utilities/Title'

export const HeroContainer = styled.div`

  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;


  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    justify-content: center;
  }



  .Menu-Hero {
    position: fixed;
    right: 0px;
    top: 50px;
    z-index: 1000;
    transform: rotateZ(-90deg) translate(25%, 50%);
    background-color: ${props => props.theme.colors.primaryGreen};
    padding: 12px;
    // padding-bottom: 20px;
    border-radius: 5px;
    opacity: 0.8;
    font-size: 12px;
  }
`

export const ImageHeroContainer = styled.div`
  height: 600px;
  width: 100%;
  background-image: url('https://images.prismic.io/skischoolrokytnice/9bc35888-196e-437b-a747-9455f0135a32_DSC_1814.jpg?auto=compress,format');
  background-attachment: initial;
  background-size: cover;
  background-position: 60% 50%;
  transition: transform 2s;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    min-height: 700px;
    background-attachment: fixed;
  }

  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    min-height: 80vh;
  }

  &:hover {
    transform: scale(1.10);
  }

`

export const SponsorLogoContainer = styled(Image)`
  width: 140px;
  height: 20px;
  position: absolute !important;
  left: -52px;
  bottom: 20%;
  transform: rotate(-90deg);

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    width: 270px;
    height: 64px;
    bottom: 25%;
    left: -105px;
  }
`

export const HeroContent = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: baseline;
  flex-direction: column;
  padding: 0px 20px;
  position: absolute;
  // margin-left: 70px;

  @media (min-width: ${props => props.theme.breakpoints.mdSmall}) {
    padding: initial;
    margin-left: 0px;
  }

  svg {
    width: 100px;
    height: 100px;
  }

  h1 {
    letter-spacing: 2.485px;
  }

  h3 {
    letter-spacing: 1.285px;
  }

  .btn-hero {
    position: relative;
    transition: all 0.5s;
    outline: none;
    overflow: hidden;
    border: none;
    color: white;
    z-index: 0;

    &:after {
      content: '';
      position: absolute;
      z-index: -1;
      transition: all 0.5s;
      width: 100%;
      height: 0;
      top: 50%;
      left: 50%;
      background: ${props => props.theme.colors.primaryBlack};
      opacity: 0;
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
    }

    &:hover {
      &:after {
        height: 260%;
        opacity: 1;
      }
    }

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      max-width: 150px;
      width: 150px;
    }
    @media (min-width: ${props => props.theme.breakpoints.l}) {
      max-width: 200px;
      width: 200px;
    }
  }
`

export const HeroTitle = styled(Title)`
  color: ${props => props.theme.colors.secondaryBlack};
  margin-bottom: 10px;
  position: relative;
  z-index: 100;
  font-weight: 600;
  font-family: "Montserrat";


  @media (min-width: ${props => props.theme.breakpoints.md}) {
    color: ${props => props.theme.colors.secondaryBlack};
    margin-bottom: 20px;
    font-size: 36px;
    text-transform: uppercase;
  }

  @media (min-width: ${props => props.theme.breakpoints.l}) {
    font-size: 56px;
  }
`

export const HeroBody = styled(Title)`
  color: ${props => props.theme.colors.secondaryBlack};
  margin-bottom: 10px;
  font-weight: 700;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    font-size: ${props => props.theme.fonts.xs}; 
    margin-bottom: 40px;
  }

  @media (min-width: ${props => props.theme.breakpoints.l}) {
    font-size: ${props => props.theme.fonts.xl}; 
  }
`

export const FullWidthImage = styled(Image)`
  width: 100%;
  height: auto;
  min-height: 500px;
`

export const FullWidthImageDesktopSecondary = styled.div`
  height: 500px;
  width: 100%;
  background-image: url('https://images.prismic.io/skischoolrokytnice/b9731785-7fc6-43cb-aec2-062b09fb619e_DSC_1372.jpg?auto=compress,format');
  // background-attachment: fixed;
  background-size: cover;
  background-position: 80% 50%;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    height: 700px;
    background-position: 50% 50%;
  }
`
export const MenuText = styled(Title)`
font-weight: bold;
`
export const FullWidthImageDesktopPrimary = styled.div`
  height: 500px;
  width: 100%;
  background-image: url('https://images.prismic.io/skischoolrokytnice/0b6b6101-0e70-4a07-85dc-bf820fa57622_FullWidth.jpg?auto=compress,format');
  // background-attachment: fixed;
  background-size: cover;
  background-position: 60% 50%;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    height: 700px;
    background-position: 50% 50%;
  }
`
export const Logo =styled.img`
width: 50px;
position: absolute;
top: 10px;
left: 10px;

@media (min-width: ${props => props.theme.breakpoints.md}) {
  display: none;
}

`
