import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import {
  MobilePricingContainer,
  PricingContainerMainStyled,
  MainTitle,
  TitleDeco,
  PricingCardContainer,
  PriceCard,
  Header,
  Content,
  PricingTitle,
  IconContainer,
  Row,
  PricingFooter,
} from './style'
import { makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { getQuery } from 'functions/getQuery'
import Text from 'components/Utilities/Text'
import AnimatedLine from 'components/AnimatedLine'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  summary: {
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  details: {
    width: '100%',
    paddingTop: '20px',
  },
}))

const MobilePricing = (props, ref) => {
  const { query, pricingQuery } = props
  const locale = props.locale
  const classes = useStyles()
  const PricingSectiontQuery = getQuery(['pricing', 'document', 'data'], query)
  const singlePriceList = PricingSectiontQuery.pricing_cards[0]
  const doublePriceList = PricingSectiontQuery.pricing_cards[1]
  const multiPriceList = PricingSectiontQuery.pricing_cards[2]
  const individualBasic = PricingSectiontQuery.pricing_cards[3]
  const multiBasic = PricingSectiontQuery.pricing_cards[4]

  function createMarkup() {
    return { __html: PricingSectiontQuery.pricing_body_comments.html }
  }

  return (
    <MobilePricingContainer
      ref={ref}
      id={locale === 'cs-cz' ? 'cenik' : 'prices'}
    >
      <TitleDeco>
        <MainTitle type="h3" as='h3' uppercase>
          {PricingSectiontQuery.pricing_title.text}
        </MainTitle>
        <AnimatedLine className="AnimatedLine__ProjectExcerpt" />
      </TitleDeco>
      <div className={classes.root}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ fill: '#68a24b' }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={classes.summary}
          >
            <Header>
              <IconContainer
                fluid={pricingQuery.icon_1.localFile.childImageSharp.fluid}
                style={{ width: '20px', height: '35px' }}
              ></IconContainer>
              <PricingTitle type="h1-secondary" uppercase>
                {PricingSectiontQuery.pricing_title_1.text}
              </PricingTitle>
            </Header>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <Content>
              <Row>
                <p>{singlePriceList.price_tier_title_1.text}</p>
                <p>{singlePriceList.price_tier_1.text}</p>
              </Row>
              <Row>
                <p>{singlePriceList.price_tier_title_2.text}</p>
                <p>{singlePriceList.price_tier_2.text}</p>
              </Row>
              <Row>
                <p>{singlePriceList.price_tier_title_3.text}</p>
                <p>{singlePriceList.price_tier_3.text}</p>
              </Row>
              <Row>
                <p>{singlePriceList.price_tier_title_4.text}</p>
                <p>{singlePriceList.price_tier_4.text}</p>
              </Row>
              <Row>
                <p>{singlePriceList.price_tier_title_5.text}</p>
                <p>{singlePriceList.price_tier_5.text}</p>
              </Row>
              <Row>
                <p>{singlePriceList.price_tier_title_6.text}</p>
                <p>{singlePriceList.price_tier_6.text}</p>
              </Row>
            </Content>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ fill: '#68a24b' }} />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            className={classes.summary}
          >
            <Header>
              <IconContainer
                fluid={pricingQuery.icon_2.localFile.childImageSharp.fluid}
                style={{ width: '54px', height: '35px' }}
              ></IconContainer>
              <PricingTitle type="h1-secondary" uppercase>
                {PricingSectiontQuery.pricing_title_2.text}
              </PricingTitle>
            </Header>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <Content>
              <Row>
                <p>{doublePriceList.price_tier_title_1.text}</p>
                <p>{doublePriceList.price_tier_1.text}</p>
              </Row>
              <Row>
                <p>{doublePriceList.price_tier_title_2.text}</p>
                <p>{doublePriceList.price_tier_2.text}</p>
              </Row>
              <Row>
                <p>{doublePriceList.price_tier_title_3.text}</p>
                <p>{doublePriceList.price_tier_3.text}</p>
              </Row>
              <Row>
                <p>{doublePriceList.price_tier_title_4.text}</p>
                <p>{doublePriceList.price_tier_4.text}</p>
              </Row>
              <Row>
                <p>{doublePriceList.price_tier_title_5.text}</p>
                <p>{doublePriceList.price_tier_5.text}</p>
              </Row>
              <Row>
                <p>{doublePriceList.price_tier_title_6.text}</p>
                <p>{doublePriceList.price_tier_6.text}</p>
              </Row>
            </Content>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ fill: '#68a24b' }} />}
            aria-controls="panel3a-content"
            id="panel3a-header"
            className={classes.summary}
          >
            <Header>
              <IconContainer
                fluid={pricingQuery.icon_3.localFile.childImageSharp.fluid}
                style={{ width: '76px', height: '44px' }}
              ></IconContainer>
              <PricingTitle type="h1-secondary" uppercase>
                {PricingSectiontQuery.pricing_title_3.text}
              </PricingTitle>
            </Header>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <Content>
              <Row>
                <p>{multiPriceList.price_tier_title_1.text}</p>
                <p>{multiPriceList.price_tier_1.text}</p>
              </Row>
              <Row>
                <p>{multiPriceList.price_tier_title_2.text}</p>
                <p>{multiPriceList.price_tier_2.text}</p>
              </Row>
              <Row>
                <p>{multiPriceList.price_tier_title_3.text}</p>
                <p>{multiPriceList.price_tier_3.text}</p>
              </Row>
              <Row>
                <p>{multiPriceList.price_tier_title_4.text}</p>
                <p>{multiPriceList.price_tier_4.text}</p>
              </Row>
              <Row>
                <p>{multiPriceList.price_tier_title_5.text}</p>
                <p>{doublePriceList.price_tier_5.text}</p>
              </Row>
              <Row>
                <p>{multiPriceList.price_tier_title_6.text}</p>
                <p>{multiPriceList.price_tier_6.text}</p>
              </Row>
            </Content>
          </AccordionDetails>
        </Accordion>
        {/* <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ fill: '#68a24b' }} />}
            aria-controls="panel3a-content"
            id="panel3a-header"
            className={classes.summary}
          >
            <Header>
              <PricingTitle type="h1-secondary" uppercase>
                {PricingSectiontQuery.pricing_title_4.text}
              </PricingTitle>
            </Header>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <Content>
              <Row>
                <p>{PricingSectiontQuery.pricing_smluvni_title_1.text}</p>
              </Row>
              <Row>
                <p>{individualBasic.price_tier_title_1.text}</p>
                <p>{individualBasic.price_tier_1.text}</p>
              </Row>
              <Row>
                <p>{individualBasic.price_tier_title_2.text}</p>
                <p>{individualBasic.price_tier_2.text}</p>
              </Row>
              <Row>
                <p>{individualBasic.price_tier_title_3.text}</p>
                <p>{individualBasic.price_tier_3.text}</p>
              </Row>
              <Row>
                <p>{individualBasic.price_tier_title_4.text}</p>
                <p>{individualBasic.price_tier_4.text}</p>
              </Row>
              <Row>
                <p>{PricingSectiontQuery.pricing_smluvni_title_2.text}</p>
              </Row>
              <Row>
                <p>{multiBasic.price_tier_title_1.text}</p>
                <p>{multiBasic.price_tier_1.text}</p>
              </Row>
              <Row>
                <p>{multiBasic.price_tier_title_2.text}</p>
                <p>{multiBasic.price_tier_2.text}</p>
              </Row>
              <Row>
                <p>{multiBasic.price_tier_title_3.text}</p>
                <p>{multiBasic.price_tier_3.text}</p>
              </Row>
              <Row>
                <p>{multiBasic.price_tier_title_4.text}</p>
                <p>{multiBasic.price_tier_4.text}</p>
              </Row>
            </Content>
          </AccordionDetails>
        </Accordion> */}
      </div>
      <PricingFooter>
        <Text
          dangerouslySetInnerHTML={createMarkup()}
          type="super-small"
        ></Text>
      </PricingFooter>
    </MobilePricingContainer>
  )
}

MobilePricing.propTypes = {}

export default forwardRef(MobilePricing)
