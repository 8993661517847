import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import {
  ReservationContainerStyled,
  TitleDeco,
  MainTitle,
  MainBody,
  SecondaryTitle,
  EmailStyled,
  PhoneStyled,
} from './style'
import AnimatedLine from 'components/AnimatedLine'
import Text from 'components/Utilities/Text'

const ReservationContainer = (props, ref) => {
  const { query } = props
  const locale = props.locale
  console.log(query)
  function createMarkup() {
    return { __html: query.reservation_subsection_body.html }
  }
  function createSecondaryMarkup() {
    return { __html: query.reservation_subsection_body_2.html }
  }
  return (
    <ReservationContainerStyled
      id={locale === 'cs-cz' ? 'rezervace' : 'reservation'}
      ref={ref}
    >
      <TitleDeco>
        <MainTitle type="h3" as="h3">
          {query.reservation_title_main.text}
        </MainTitle>
        <AnimatedLine className="AnimatedLine__ProjectExcerpt" />
      </TitleDeco>
      <SecondaryTitle type="primary" uppercase>
        {query.reservation_subsection_title.text}
      </SecondaryTitle>
      <MainBody
        type="primary"
        as="div"
        dangerouslySetInnerHTML={createMarkup()}
      ></MainBody>{' '}
      <EmailStyled type="primary">
        <a href={`mailto:${query.email_1.text}`}>{query.email_1.text}</a>
      </EmailStyled>
      <MainBody
        as="div"
        type="primary"
        dangerouslySetInnerHTML={createSecondaryMarkup()}
      ></MainBody>{' '}
      <PhoneStyled type="primary">
        <a href={`tel:${query.phone_1.text}`}>{query.phone_1.text}</a>
      </PhoneStyled>
      <MainBody type="primary">
        {query.reservation_subsection_body_3.text}
      </MainBody>
    </ReservationContainerStyled>
  )
}

ReservationContainer.propTypes = {}

export default forwardRef(ReservationContainer)
