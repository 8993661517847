import React, { forwardRef, useState } from 'react'
import PropTypes from 'prop-types'
import {
  IntroContainerMain,
  MainContentContainer,
  MainTitle,
  MainBody,
  TitleDeco,
  SecondaryTitle,
  SecondaryBody,
} from './style'
import AnimatedLine from 'components/AnimatedLine'

const IntroContainer = (props, ref) => {
  const { query } = props

  const locale = props.locale
  console.log(query, "query")
  function createMarkup() {
    return { __html: query?.intro_subsection1_body?.html }
  }
  return (
    <IntroContainerMain

    >
      <MainContentContainer>

          <TitleDeco>
            <MainTitle type="h3" as="h3">
              {query.intro_section_title.text}
            </MainTitle>
            <AnimatedLine className="AnimatedLine__ProjectExcerpt" />
          </TitleDeco>
          <MainBody type="primary">{query.intro_section_body.text}</MainBody>
        

        
          <TitleDeco>
            <SecondaryTitle type="h3" as="h3" uppercase>
              {query.intro_subsection1_title.text}
            </SecondaryTitle>{' '}
            <AnimatedLine className="AnimatedLine__ProjectExcerpt" />
          </TitleDeco>
          <MainBody type="primary" dangerouslySetInnerHTML={createMarkup()}>
          </MainBody>

          
        
          <TitleDeco>
            <SecondaryTitle type="h3" as="h3" uppercase>
              {query.intro_subsection2_title.text}
            </SecondaryTitle>{' '}
            <AnimatedLine className="AnimatedLine__ProjectExcerpt" />
          </TitleDeco>
          <MainBody type="primary">
            {' '}
            {query.intro_subsection2_body.text}{' '}
          </MainBody>
        
      </MainContentContainer>
    </IntroContainerMain>
  )
}

IntroContainer.propTypes = {}

export default forwardRef(IntroContainer)
