import React from "react"
import { Link } from "gatsby"
import LocaleContext from "context/LocaleProvider"
import {LanguageSwitcherContainer} from "./style"
import { ENGFlag } from "../Icon/assets/ENGFlag"
import { CZFlag } from "../Icon/assets/CZFlag"


const LocaleComponent = ({ location }) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  console.log("locale", i18n.locale);
  return (
    <LanguageSwitcherContainer>
      {i18n.locale === "en-gb" ? (
        <Link
          to={`/${
            location.pathname.includes("/en")
              ? location.pathname.replace("/en", "")
              : location.pathname
          }`}
          className="Header__LanguageSwitcher-button"
        >
          <CZFlag/>
        </Link>
      ) : i18n.locale === "cs-cz" ? (
        <Link
          to={`en/${location.pathname}`}
          className="Header__LanguageSwitcher-button"
        >
          <ENGFlag/>
        </Link>
      ) : (
        ""
      )}
    </LanguageSwitcherContainer>
  )
}

export default LocaleComponent
