import styled from '@emotion/styled'


export const FooterContainerMain = styled.div`
min-height: 50px;
width: 100%;
background-color: ${props => props.theme.colors.secondaryBlack};
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
text-align: center;
padding-bottom: 20px;

p {
  font-weight: 600;
  margin-top: 12px;
}

`