import React, { useState, forwardRef } from 'react'
import PropTypes from 'prop-types'
import {
  FooterContainerStyled,
  FooterContent,
  Row,
  StyledText,
  Contacts,
  Container,
  MainTitle,
  MapContainer,
} from './style'
import { Mobile, Desktop } from 'components/Utilities/Media'
import Button from 'components/Utilities/Button'
import Icon from 'components/Icon'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import GoogleMapReact from 'google-map-react'


const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    width: '100vw',
    height: '100vh',
    [theme.breakpoints.up('md')]: {
      width: '600px',
      height: '600px',
    },
  },
}))(MuiDialogContent)

const ContactFooter = (props, ref) => {
  const { query, tertiaryHero, map } = props
  const locale = props.locale
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const defaultProps = {
    center: { lat: 50.72, lng: 15.46 },
    zoom: 11,
  }
  return (
    <Container id={locale === 'cs-cz' ? 'kontakt' : 'contact'} ref={ref}>
        <div style={{ textAlign: 'center' }}>
          <FooterContainerStyled />
          <Desktop>
            <FooterContent>
              <Contacts>
                <MainTitle type="h3" as="h3" uppercase whiteTitle>
                  {query.contact_section_main_title.text}
                </MainTitle>
                <Row>
                  <StyledText whiteText type="primary">
                    {query.contact_1_body.text}
                  </StyledText>
                  <StyledText type="primary">
                    {' '}
                    <a href={`tel:${query.contact_1_content.text}`}>
                      {query.contact_1_content.text}
                    </a>
                  </StyledText>
                </Row>
                <Row>
                  <StyledText whiteText type="primary">
                    {query.contact_2_body.text}
                  </StyledText>
                  <StyledText type="primary">
                    {' '}
                    <a href={`tel:${query.contact_2_content.text}`}>
                      {query.contact_2_content.text}
                    </a>
                  </StyledText>
                </Row>
                <Row>
                  <StyledText whiteText type="primary">
                    {query.contact_3_body.text}
                  </StyledText>
                  <StyledText type="primary">
                    {' '}
                    <a href={`tel:${query.contact_3_content.text}`}>
                      {query.contact_3_content.text}
                    </a>
                  </StyledText>
                </Row>
              </Contacts>
              <Button
                buttonStyle="primary"
                clickHandler={handleClickOpen}
                className="btn-animated"
              >
                {query.cta_contact_label.text}
              </Button>
            </FooterContent>
          </Desktop>
        </div>
      <Mobile>
        <FooterContent>
          <MainTitle type="h3" as="h3" uppercase whiteTitle>
            {query.contact_section_main_title.text}
          </MainTitle>
          <Contacts>
            <Row>
              <StyledText whiteText type="primary">
                {query.contact_1_body.text}
              </StyledText>
              <StyledText type="primary">
                {' '}
                <a href={`tel:${query.contact_1_content.text}`}>
                  {query.contact_1_content.text}
                </a>
              </StyledText>
            </Row>
            <Row>
              <StyledText whiteText type="primary">
                {query.contact_2_body.text}
              </StyledText>
              <StyledText type="primary">
                {' '}
                <a href={`tel:${query.contact_2_content.text}`}>
                  {query.contact_2_content.text}
                </a>
              </StyledText>
            </Row>
            <Row>
              <StyledText whiteText type="primary">
                {query.contact_3_body.text}
              </StyledText>
              <StyledText type="primary">
                {' '}
                <a href={`tel:${query.contact_3_content.text}`}>
                  {query.contact_3_content.text}
                </a>
              </StyledText>
            </Row>
          </Contacts>
          <Button buttonStyle="primary" clickHandler={handleClickOpen}>
            {query.cta_contact_label.text}
          </Button>
        </FooterContent>
      </Mobile>
      <div>
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          ></DialogTitle>
          <DialogContent>
            <MapContainer src={map.mapimage.url}></MapContainer>

            {/* <GoogleMapReact {...defaultProps}></GoogleMapReact> */}
          </DialogContent>
        </Dialog>
      </div>
    </Container>
  )
}

ContactFooter.propTypes = {}

export default forwardRef(ContactFooter)
