import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { getQuery } from 'functions/getQuery'
import IndexLayout from '../components/PagesLayout/index'
import LocaleContext from '../context/LocaleProvider'

const Index = ({ location, data, props }) => {
  const landingPagetQuery = getQuery(['landing', 'nodes', 0, 'data'], data)
  const heroQuery = getQuery(['hero', 'nodes', [0],'data', 'hero_image'], data)
  const secondaryHeroQuery = getQuery(['hero', 'nodes', [0],'data', 'secondary_hero'], data)
  const tertiaryHeroQuery = getQuery(['hero', 'nodes', [0],'data', 'tertiary_hero'], data)
  const sponsorLogoQuery = getQuery(['hero', 'nodes', [0],'data', 'sponsor_logo'], data)
  const pricingQuery = getQuery(['pricing', 'data'], data)
  const mobileSpecificHero = getQuery(['hero','nodes', [0], 'data'], data)
  const map = getQuery(['map', 'data'], data)

  console.log("location", location)
  const lang = useContext(LocaleContext)
  const locale = lang.locale




  return (
    <>
      <IndexLayout
        location={location}
        query={landingPagetQuery}
        heroQuery={heroQuery}
        secondaryHero={secondaryHeroQuery}
        pricingQuery={pricingQuery}
        tertiaryHero={tertiaryHeroQuery}
        locale={locale}
        sponsorLogoQuery={sponsorLogoQuery}
        mobileSpecificHero={mobileSpecificHero}
        map={map}
      />
    </>
  )
}

export default Index

Index.propTypes = {
  location: PropTypes.object.isRequired,
}

export const homepageQuery = graphql`
  query LandingPageQuery($locale: String!) {
    map: prismicMapimage {
      data {
        mapimage {
          url
        }
      }
    }
    pricing: prismicPricing {
      data {
        icon_1 {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        icon_2 {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        icon_3 {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    hero: allPrismicHeroimage(filter: { lang: { eq: $locale } }) {
      nodes {
        lang
        id
        data {
          hero_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 3000, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          secondary_hero {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          tertiary_hero {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          main_button_content {
            text
          }
          sponsor_logo {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          mobile_specific_hero_main_title {
            text
          }
          mobile_specific_hero_subtitle {
            text
          }
        }
      }
    }

    landing: allPrismicLandingpage(filter: { lang: { eq: $locale } }) {
      nodes {
        id
        lang
        data {
          fullwidth_image {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          rental_image {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          rental_body {
            text
          }
          rental_contact_tel_1 {
            text
          }
          rental_contact_tel_2 {
            text
          }
          rental_email {
            text
          }
          rental_notes {
            text
            html
          }
          rental_title {
            text
          }
          organization_card_1_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 500, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          organization_card_2_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 500, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          organization_card_3_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 500, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          contact_1_body {
            text
          }
          contact_1_content {
            text
          }
          contact_2_body {
            text
          }
          contact_2_content {
            text
          }
          contact_3_body {
            text
          }
          contact_3_content {
            text
          }
          contact_section_main_title {
            text
          }
          cta_contact_label {
            text
          }
          email_1 {
            text
          }
          email_2 {
            text
          }
          hero_banner {
            document {
              ... on PrismicHeroBanner {
                id
                data {
                  title {
                    text
                  }
                  subtitle {
                    text
                  }
                  cta_button_link {
                    text
                  }
                  cta_button_label {
                    text
                  }
                }
              }
            }
          }
          instruction_section_body {
            text
            html
          }
          instruction_section_title {
            text
          }
          intro_section_body {
            text
          }
          intro_section_title {
            text
          }
          intro_subsection1_body {
            text
            html
          }
          intro_subsection1_title {
            text
          }
          intro_subsection2_body {
            text
          }
          intro_subsection2_title {
            text
          }
          menu_items {
            document {
              ... on PrismicMenu {
                id
                data {
                  menu_items {
                    menu_item_label {
                      text
                    }
                    menu_item_uid {
                      text
                    }
                  }
                }
              }
            }
          }
          organization_card_1 {
            text
          }
          organization_card_2 {
            text
          }
          organization_card_3 {
            text
          }
          organization_title_main {
            text
          }
          phone_1 {
            text
          }
          phone_2 {
            text
          }
          pricing {
            document {
              ... on PrismicPricing {
                id
                data {
                  pricing_body_comments {
                    text
                    html
                  }
                  pricing_cards {
                    price_tier_1 {
                      text
                    }
                    price_tier_2 {
                      text
                    }
                    price_tier_3 {
                      text
                    }
                    price_tier_4 {
                      text
                    }
                    price_tier_5 {
                      text
                    }
                    price_tier_6 {
                      text
                    }
                    price_tier_title_1 {
                      text
                    }
                    price_tier_title_2 {
                      text
                    }
                    price_tier_title_3 {
                      text
                    }
                    price_tier_title_4 {
                      text
                    }
                    price_tier_title_5 {
                      text
                    }
                    price_tier_title_6 {
                      text
                    }
                  }
                  pricing_smluvni_title_1 {
                    text
                  }
                  pricing_smluvni_title_2 {
                    text
                  }
                  pricing_title {
                    text
                  }
                  pricing_title_1 {
                    text
                  }
                  pricing_title_2 {
                    text
                  }
                  pricing_title_3 {
                    text
                  }
                  pricing_title_4 {
                    text
                  }
                }
              }
            }
          }
          reservation_subsection_body {
            text
            html
          }
          reservation_subsection_body_2 {
            text
            html
          }
          reservation_subsection_body_3 {
            text
            html
          }
          reservation_subsection_title {
            text
          }
          reservation_title_main {
            text
          }
          instructors_body {
            text
          }
          instructors_contact {
            text
            html
          }
          instructors_email {
            text
          }
          instructors_phone {
            text
          }
          instructors_title {
            text
          }
        }
      }
    }
  }
`
