import styled from '@emotion/styled'
import Title from 'components/Utilities/Title'
import Text from 'components/Utilities/Text'
import Image from 'gatsby-image'

export const PricingContainerMainStyled = styled.div`
width: 100%;
max-width: 1440px;
margin: 0px auto;
margin-bottom: 100px;
padding-right: 200px;
padding-left: 200px;

// @media (min-width: ${props => props.theme.breakpoints.md}) {
//   height: auto;
//   max-height: unset;
//   padding-right: 200px;
//   padding-left: 200px;
//   margin-bottom: 100px;
//   padding-bottom: 0;
//   padding-top: 40px;
// }

`

export const MainTitle = styled(Title)`
  font-weight: bold;
  text-align: left;
  margin-bottom: 40px;
`

export const TitleDeco = styled.div`
  position: relative;
  // max-width: 1440px;
  // margin: 0 auto;
  // width: 100%;
`

export const PricingCardContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  max-width: 1440px;
  justify-content: center;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    height: auto;
    max-height: unset;
    padding-right: 200px;
    padding-left: 200px;
    margin-bottom: 100px;
    padding-bottom: 0;
    padding-top: 40px;
  }


`

export const PriceCard = styled.div`
  background-color: ${props => props.theme.colors.white};
  min-width: 250px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  margin-right: 20px;
  margin-left: 20px;
  padding: 20px;
  position: relative;
  transition: box-shadow .7s;


  @media (min-width: ${props => props.theme.breakpoints.md}) {
    max-height: 550px;
    min-height: 550px;
    padding-top: 40px;
    margin-right: 10px;
    margin-left: 10px;
  }

  @media (min-width: ${props => props.theme.breakpoints.xxl}) {

    padding-top: 40px;
    margin-right: 40px;
    margin-left: 40px;
  }



  .label {
    position: absolute;
    top: 0;
    right: 0;
  }

  &:hover {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.35);
  }

`

export const Header = styled(Title)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
`

export const IconContainer = styled(Image)`


`

export const PricingTitle = styled(Title)`
  font-weight: 400;
  text-align: left;
`

export const Content = styled.div``

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const PricingFooter = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  max-width: 1440px;
  margin-top: 50px;
  padding-left: 120px;
  font-style: italic;
  font-size: 8px;
  margin-bottom: 100px;
  max-width: 1440px;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    font-size: 8px;
    padding-left: 20px;
  }
`

