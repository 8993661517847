import styled from '@emotion/styled'
import Title from 'components/Utilities/Title'
import Text from 'components/Utilities/Text'
import Image from 'gatsby-image'

export const ReservationContainerStyled = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
  margin: 40px auto;
  padding: 0px 20px;
  flex-direction: column;
  max-width: 800px;


  @media (min-width: ${props => props.theme.breakpoints.md}) {
    margin: 100px auto;
    padding-right: 200px;
    padding-left: 200px;
    max-width: 1440px;
  }
`

export const TitleDeco = styled.div`
position: relative;
// max-width: 800px;
margin: 0 auto;
width: 100%;
`

export const MainTitle = styled(Title)`
font-weight: bold;
// padding-bottom: 30px;
text-align: left;


@media(min-width: ${props => props.theme.breakpoints.md}) {
  // margin-bottom: 40px;
}

`

export const MainBody = styled(Text)`
font-weight: 400;
letter-spacing: 0.575px;
text-align: left;

@media(min-width: ${props => props.theme.breakpoints.md}) {
  // margin-top: 40px;
}
a {
  text-decoration: none;
}

`

export const SecondaryTitle = styled(Text)`
font-weight: bold;
text-align: left;
// font-size: 16px;
text-transform: uppercase;
margin-top: 40px;

`


export const EmailStyled = styled(Text)`
font-family: Work Sans;
font-weight: 400;
text-transform: none;
color: #1B1725;
margin-bottom: 0px;
// font-size: 16px;
font-weight: 500;
text-align: left;
a {
  text-decoration: none;
  color: ${props => props.theme.colors.secondaryBlack};
}
`

export const PhoneStyled = styled(Text)`
font-family: Work Sans;
font-weight: 400;
text-transform: none;
color: #1B1725;
margin-bottom: 20px;
// font-size: 16px;
font-weight: 500;
text-align: left;


a {
  text-decoration: none;
  color: ${props => props.theme.colors.secondaryBlack};
}
`