import React from 'react'
import PropTypes from 'prop-types'
import { FooterContainerMain } from './style'
import { StyledText } from '../ContactFooter/style'

const Footer = props => {
  return (
    <FooterContainerMain>
      <StyledText type="super-small" whiteText>
        Hynek Major <br/> Horní 715, 512 45, Rokytnice nad Jizerou, <br/>IČ: 42923930 DIČ:CZ 7302263177
        <br/><a href={`mailto:info@majorski.cz`}>info@majorski.cz</a>
      </StyledText>
      <StyledText type="super-small" whiteText>
        ©2021 Copyright Major Ski School
      </StyledText>
    </FooterContainerMain>
  )
}

Footer.propTypes = {}

export default Footer
