import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { getQuery } from 'functions/getQuery'
import {
  PricingContainerMainStyled,
  MainTitle,
  TitleDeco,
  PricingCardContainer,
  PriceCard,
  Header,
  Content,
  PricingTitle,
  IconContainer,
  Row,
  PricingFooter,
} from './style'
import Text from 'components/Utilities/Text'
import AnimatedLine from 'components/AnimatedLine'
import Icon from 'components/Icon'

const PricingContainer = (props, ref) => {
  const { query, pricingQuery } = props
  const locale = props.locale

  const PricingSectiontQuery = getQuery(['pricing', 'document', 'data'], query)
  console.log('pricingQuery', PricingSectiontQuery)

  const singlePriceList = PricingSectiontQuery.pricing_cards[0]
  const doublePriceList = PricingSectiontQuery.pricing_cards[1]
  const multiPriceList = PricingSectiontQuery.pricing_cards[2]
  const individualBasic = PricingSectiontQuery.pricing_cards[3]
  const multiBasic = PricingSectiontQuery.pricing_cards[4]

  function createMarkup() {
    return { __html: PricingSectiontQuery.pricing_body_comments.html }
  }

  return (
    <>
      <PricingContainerMainStyled
        id={locale === 'cs-cz' ? 'cenik' : 'prices'}
        ref={ref}
      >
        <TitleDeco>
          <TitleDeco>
            <MainTitle type="h3" as="h3" uppercase>
              {PricingSectiontQuery.pricing_title.text}
            </MainTitle>
          </TitleDeco>

          <AnimatedLine className="AnimatedLine__ProjectExcerpt" />
        </TitleDeco>

        <PricingCardContainer>
          <PriceCard>
            <Header>
              <IconContainer
                fluid={pricingQuery.icon_1.localFile.childImageSharp.fluid}
                style={{ width: '20px', height: '35px' }}
              ></IconContainer>
              <PricingTitle type="h1-secondary">
                {PricingSectiontQuery.pricing_title_1.text}
              </PricingTitle>
            </Header>
            <Content>
              <Row>
                <p>{singlePriceList.price_tier_title_1.text}</p>
                <p>{singlePriceList.price_tier_1.text}</p>
              </Row>
              <Row>
                <p>{singlePriceList.price_tier_title_2.text}</p>
                <p>{singlePriceList.price_tier_2.text}</p>
              </Row>
              <Row>
                <p>{singlePriceList.price_tier_title_3.text}</p>
                <p>{singlePriceList.price_tier_3.text}</p>
              </Row>
              <Row>
                <p>{singlePriceList.price_tier_title_4.text}</p>
                <p>{singlePriceList.price_tier_4.text}</p>
              </Row>
              <Row>
                <p>{singlePriceList.price_tier_title_5.text}</p>
                <p>{singlePriceList.price_tier_5.text}</p>
              </Row>
              <Row>
                <p>{singlePriceList.price_tier_title_6.text}</p>
                <p>{singlePriceList.price_tier_6.text}</p>
              </Row>
            </Content>
            {locale === 'cs-cz' ? (
              <Icon type="label" className="label" />
            ) : (
              <Icon type="label-eng" className="label" />
            )}
          </PriceCard>

          <PriceCard>
            <Header>
              <IconContainer
                fluid={pricingQuery.icon_2.localFile.childImageSharp.fluid}
                style={{ width: '54px', height: '35px' }}
              ></IconContainer>
              <PricingTitle type="h1-secondary">
                {PricingSectiontQuery.pricing_title_2.text}
              </PricingTitle>
            </Header>
            <Content>
              <Row>
                <p>{doublePriceList.price_tier_title_1.text}</p>
                <p>{doublePriceList.price_tier_1.text}</p>
              </Row>
              <Row>
                <p>{doublePriceList.price_tier_title_2.text}</p>
                <p>{doublePriceList.price_tier_2.text}</p>
              </Row>
              <Row>
                <p>{doublePriceList.price_tier_title_3.text}</p>
                <p>{doublePriceList.price_tier_3.text}</p>
              </Row>
              <Row>
                <p>{doublePriceList.price_tier_title_4.text}</p>
                <p>{doublePriceList.price_tier_4.text}</p>
              </Row>
              <Row>
                <p>{doublePriceList.price_tier_title_5.text}</p>
                <p>{doublePriceList.price_tier_5.text}</p>
              </Row>
              <Row>
                <p>{doublePriceList.price_tier_title_6.text}</p>
                <p>{doublePriceList.price_tier_6.text}</p>
              </Row>
            </Content>
          </PriceCard>

          <PriceCard>
            <Header>
              <IconContainer
                fluid={pricingQuery.icon_3.localFile.childImageSharp.fluid}
                style={{ width: '76px', height: '44px' }}
              ></IconContainer>
              <PricingTitle type="h1-secondary">
                {PricingSectiontQuery.pricing_title_3.text}
              </PricingTitle>
            </Header>
            <Content>
              <Row>
                <p>{multiPriceList.price_tier_title_1.text}</p>
                <p>{multiPriceList.price_tier_1.text}</p>
              </Row>
              <Row>
                <p>{multiPriceList.price_tier_title_2.text}</p>
                <p>{multiPriceList.price_tier_2.text}</p>
              </Row>
              <Row>
                <p>{multiPriceList.price_tier_title_3.text}</p>
                <p>{multiPriceList.price_tier_3.text}</p>
              </Row>
              <Row>
                <p>{multiPriceList.price_tier_title_4.text}</p>
                <p>{multiPriceList.price_tier_4.text}</p>
              </Row>
              <Row>
                <p>{multiPriceList.price_tier_title_5.text}</p>
                <p>{multiPriceList.price_tier_5.text}</p>
              </Row>
              <Row>
                <p>{multiPriceList.price_tier_title_6.text}</p>
                <p>{multiPriceList.price_tier_6.text}</p>
              </Row>
            </Content>
          </PriceCard>

          {/* <PriceCard style={{ marginRight: '0' }}>
            <Header>
              <PricingTitle type="h1-secondary">
                {PricingSectiontQuery.pricing_title_4.text}
              </PricingTitle>
            </Header>
            <Content>
              <Row>
                <p>{PricingSectiontQuery.pricing_smluvni_title_1.text}</p>
              </Row>
              <Row>
                <p>{individualBasic.price_tier_title_1.text}</p>
                <p>{individualBasic.price_tier_1.text}</p>
              </Row>
              <Row>
                <p>{individualBasic.price_tier_title_2.text}</p>
                <p>{individualBasic.price_tier_2.text}</p>
              </Row>
              <Row>
                <p>{individualBasic.price_tier_title_3.text}</p>
                <p>{individualBasic.price_tier_3.text}</p>
              </Row>
              <Row>
                <p>{individualBasic.price_tier_title_4.text}</p>
                <p>{individualBasic.price_tier_4.text}</p>
              </Row>
              <Row>
                <p>{PricingSectiontQuery.pricing_smluvni_title_2.text}</p>
              </Row>
              <Row>
                <p>{multiBasic.price_tier_title_1.text}</p>
                <p>{multiBasic.price_tier_1.text}</p>
              </Row>
              <Row>
                <p>{multiBasic.price_tier_title_2.text}</p>
                <p>{multiBasic.price_tier_2.text}</p>
              </Row>
              <Row>
                <p>{multiBasic.price_tier_title_3.text}</p>
                <p>{multiBasic.price_tier_3.text}</p>
              </Row>
              <Row>
                <p>{multiBasic.price_tier_title_4.text}</p>
                <p>{multiBasic.price_tier_4.text}</p>
              </Row>
            </Content>
          </PriceCard> */}
        </PricingCardContainer>
      </PricingContainerMainStyled>
      <PricingFooter>
        <Text
          dangerouslySetInnerHTML={createMarkup()}
          type="super-small"
        ></Text>
      </PricingFooter>
    </>
  )
}

PricingContainer.propTypes = {}

export default forwardRef(PricingContainer)
