import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import AnimatedLine from 'components/AnimatedLine'
import {
  InstructorsContainerStyled,
  MainContentContainer,
  SecondaryTitle,
  TitleDeco,
  MainBody,
  MainBodyShort,
} from './styles'

const InstructorsContainer = (props, ref) => {
  const locale = props.locale
  const query = props.query

  console.log('locale from instructores', locale)
  return (
    <div style={{backgroundColor: 'rgba(104, 162, 75, .5)'}}>
      <InstructorsContainerStyled
        ref={ref}
        id={locale === 'cs-cz' ? 'instruktori' : 'instructors'}
      >
        <MainContentContainer>
          <TitleDeco>
            <SecondaryTitle type="h3" as="h3" uppercase>
              {query?.instructors_title?.text}
            </SecondaryTitle>
            <AnimatedLine className="AnimatedLine__Dark" />
          </TitleDeco>
          <MainBody type="primary">{query?.instructors_body?.text}</MainBody>

          <MainBodyShort type="primary">
            {query?.instructors_contact?.text}
          </MainBodyShort>
        </MainContentContainer>
      </InstructorsContainerStyled>
    </div>
  )
}

InstructorsContainer.propTypes = {}

export default forwardRef(InstructorsContainer)
