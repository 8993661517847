import styled from '@emotion/styled'
import Title from 'components/Utilities/Title'
import Text from 'components/Utilities/Text'
import Image from 'gatsby-image'

export const FooterContainerStyled = styled.div`
height: 400px;
width: 100%;
background-image: url('https://images.prismic.io/skischoolrokytnice/d4634e36-206f-4409-b22f-a43ced6b92bd__MGL2259.png?auto=compress,format');
// background-attachment: fixed;
background-size: cover;
background-position: 50% 50%;
display: flex;
align-items: center;
justify-content: center;
position: relative;


@media (min-width: ${props => props.theme.breakpoints.md}) {
  min-height: 500px;
}

@media (min-width: ${props => props.theme.breakpoints.xl}) {
  min-height: 700px;
}

`
export const Container = styled.div`
position: relative;
width: 100%;
height: 100%;

.Decoration-Footer-Line {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(0, -35px);
}
`
export const FooterContent = styled.div`
background-color: ${props => props.theme.colors.secondaryBlack};
padding: 20px;
align-items: center;
display: flex;
flex-direction: column;


  @media (min-width: ${props => props.theme.breakpoints.md}) {
    max-width: 900px;
    justify-content: center;
    align-items: flex-end;
    min-height: 400px;
    background-color: ${props => props.theme.colors.primaryBlue};
    border-radius: 5px;
    position: absolute;
    bottom: 50px;
    left: 0;
  }

  .btn-animated  {
    position: relative;
    transition: all 0.5s;
    outline: none;
    overflow: hidden;
    border: none;
    color: white;
    z-index: 0;
    max-width: 200px;
    width: 200px;

    &:after {
      content: '';
      position: absolute;
      z-index: -1;
      transition: all 0.5s;
      width: 100%;
      height: 0;
      top: 50%;
      left: 50%;
      background: ${props => props.theme.colors.primaryBlack};
      opacity: 0;
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
    }

    &:hover {
      &:after {
        height: 260%;
        opacity: 1;
      }
    }
`

export const StyledText = styled(Text)`
  font-weight: 600;
  font-size: ${props => props.theme.fonts.xxxs_small};

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    font-size: ${props => props.theme.fonts.xxxs_large};
  }

  a {
    text-decoration: none;
    color: ${props => props.theme.colors.white};
    font-size: ${props => props.theme.fonts.xxxs_small};

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: ${props => props.theme.fonts.xxxs_large};
    }
  }
`

export const Row = styled.div`
  display: inline-flex;
  width: 100%;
  margin-bottom: 20px;
  align-items: center;
  justify-content: space-between;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    width: 70%;
  }
`

export const Contacts = styled.div`
display: flex;
flex-direction: column;
justify-content: space-between;
width: 100%;
margin-bottom: 40px;

@media (min-width: ${props => props.theme.breakpoints.md}) {
  width: 700px;
}
`


export const MainTitle = styled(Title)`
font-weight: bold;
margin-bottom: 40px;
text-align: left;

@media (min-width: ${props => props.theme.breakpoints.md}) {
  margin-bottom: 40px;
  margin-top: 40px;
}
`

export const MapContainer = styled.img`
width: 100%;
height: 100%;
object-fit: cover;
border-radius: 5px;
`