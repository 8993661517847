import styled from '@emotion/styled';
import 'styles/splitting-cells.css';
import 'styles/splitting.css';

export const TranslateRevealStyled = styled.div`
  .content__paragraph .word > .char,
  .whitespace {
    opacity: 0;
    transform: translate(0px, 100%);
  }

  .content__paragraph {
    opacity: 0;
  }

  .word {
    overflow: hidden;
    line-height: 1;
  }
`;
