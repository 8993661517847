import styled from '@emotion/styled'

export const LanguageSwitcherContainer = styled.div`

@media (max-width: ${props => props.theme.breakpoints.md}) {
    svg {
        width: 40px;
        margin-top: 20px;
      }
  }



`