import React from 'react'

export const Line = ({className}) => {
  return (
    <svg
      width="4"
      height="36"
      viewBox="0 0 4 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : ''}
    >
      <line
        x1="1"
        y1="35.0583"
        x2="2"
        y2="0.970695"
        stroke="#68a24b"
        strokeWidth="1"
      />
    </svg>
  )
}
