import styled from '@emotion/styled'
import Title from 'components/Utilities/Title'
import Text from 'components/Utilities/Text'
import Image from 'gatsby-image'

export const RentalContainerMain = styled.div`
  display: flex;
  width: 100%;
  max-width: 800px;
  justify-content: center;
  position: relative;
  padding: 0px 20px;
  margin: 0px auto;
  margin-bottom: 40px;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    margin: 60px auto 100px;
    padding-right: 200px;
    padding-left: 200px;
    max-width: 1440px;
    // margin-bottom: initial;
  }
`

export const SecondaryTitle = styled(Title)`
  font-weight: bold;
  margin-top: 30px;
  width: 100%;
  text-align: left;
`

export const MainBody = styled(Text)`
  font-weight: 400;
  letter-spacing: 0.575px;
  margin: 40px auto;
  text-align: left;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    position: relative;

  }

  p {
    a {
      text-decoration: none;
      color: ${props => props.theme.colors.secondaryBlack};
      font-style: italic;
    }
  }
`

export const MainBodyHTML = styled.div`
  font-weight: 400;
  letter-spacing: 0.575px;
  margin: 30px auto;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    max-width: 800px;
    padding-top: 40px;
    position: relative;
  }

  // @media (min-width: ${props => props.theme.breakpoints.l}) {
  //   width: 812px;
  // }

  p {
    font-weight: 400;
    letter-spacing: 0.575px;
    font-size: ${props => props.theme.fonts.xxxs_large};
    font-family: ${props => props.theme.fontFamily.primary};
    color: ${props => props.theme.colors.primaryBlack};
    margin-bottom: 0px;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: ${props => props.theme.fonts.xxxs_large};
    }

    a {
      text-decoration: none;
      color: ${props => props.theme.colors.secondaryBlack};
      font-style: italic;
    }
  }
`

export const TitleDeco = styled.div`
  position: relative;
`

export const MainContentContainer = styled.div`
  text-align: center;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    padding: unset;
    max-width: 800px;
  }

  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    max-width: unset;
  }
`

export const StyledImage = styled(Image)`
  max-height: 200px;
  max-width: 400px;
  margin: 0 auto;
  border-radius: 5px;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    max-height: 400px;
    max-width: 600px;
    margin: 0 auto;
    position: relative;
    width: 100%;
    z-index: -10;
  }
`

export const TextContent = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    flex-direction: column;
    position: relative;
    max-width: 1000px;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: 150px;
    width: 100%;
  }
`

export const EmailStyled = styled(Text)`
  font-family: Work Sans;
  font-weight: 400;
  text-transform: none;
  color: #1B1725;
  margin-bottom: 0px;
  font-size: 16px;
  letter-spacing: 0.575px;
  margin-bottom: 20px;
  a {
    text-decoration: none;
    color: ${props => props.theme.colors.secondaryBlack};
  }
`

export const PhoneStyled = styled(Text)`
  font-family: Work Sans;
  font-weight: 400;
  text-transform: none;
  color: #1B1725;
  margin-bottom: 0px;
  font-size: 16px;
  letter-spacing: 0.575px;
  margin-bottom: 20px;

  a {
    text-decoration: none;
    color: ${props => props.theme.colors.secondaryBlack};
  }
`
