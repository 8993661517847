import React from 'react'
import PropTypes from 'prop-types'
import {
  InstructionContainerMain,
  MainContentContainer,
  SecondaryTitle,
  MainBody,
  TitleDeco,
} from './style'
import AnimatedLine from 'components/AnimatedLine'


const InstructionContainer = (props, ref) => {
  const query = props.query
  function createMarkup() {
    return { __html: query?.instruction_section_body?.html }
  }

  return (
    <div style={{ backgroundColor: 'rgba(104, 162, 75, .5)' }}>
      <InstructionContainerMain>
        <MainContentContainer>
          <TitleDeco>
            <SecondaryTitle type="h3" as="h3" uppercase>
              {query.instruction_section_title.text}
            </SecondaryTitle>
            <AnimatedLine className="AnimatedLine__Dark" />
          </TitleDeco>
          <MainBody
            type="primary"
            dangerouslySetInnerHTML={createMarkup()}
          ></MainBody>
        </MainContentContainer>
      </InstructionContainerMain>
    </div>
  )
}

InstructionContainer.propTypes = {}

export default InstructionContainer
