import React, { useState, useRef } from 'react'
import Menu from 'components/Menu'
import IntroContainer from 'components/IntroContainer'
import InstructionContainer from 'components/InstructionContainer'
import { useWindowScroll } from 'react-use'
import Fade from 'react-reveal/Fade'
import ScrollToTop from 'react-scroll-to-top'
import { Wrapper } from 'components/Wrapper/Wrapper.css'
import {
  HeroContainer,
  ImageHeroContainer,
  HeroContent,
  HeroTitle,
  HeroBody,
  SponsorLogoContainer,
  FullWidthImageDesktopPrimary,
  FullWidthImageDesktopSecondary,
  MenuText,
  Logo,
} from './style'
import {
  Desktop,
  Mobile,
  LargeDesktopUp,
  LargeDesktopDown,
} from 'components/Utilities/Media'
import OrganizationContainer from 'components/OrganizationContainer'
import PricingContainer from 'components/PricingContainer'
import RentalContainer from 'components/RentalContainer'
import MobilePricing from 'components/MobilePricing'
import ReservationContainer from 'components/ReservationContainer'
import InstructorsContainer from 'components/InstructorsContainer'
import ContactFooter from 'components/ContactFooter'
import Footer from 'components/Footer'
import Button from 'components/Utilities/Button'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/core/styles'
import FlyoutMenu from 'components/FlyoutMenu'
import { theme as localTheme } from 'styles'

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: localTheme.colors.white,
  },
})

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    width: '100vw',
    height: '100vh',
    transition: 'opacity .7s',
  },
}))(MuiDialogContent)

const IndexLayout = ({
  location,
  query,
  heroQuery,
  secondaryHero,
  pricingQuery,
  tertiaryHero,
  locale,
  sponsorLogoQuery,
  mobileSpecificHero,
  map,
}) => {
  const [open, setOpen] = useState(false)
  const introRef = useRef(null)
  const organizaceRef = useRef(null)
  const cenikRef = useRef(null)
  const rezervaceRef = useRef(null)
  const kontaktRef = useRef(null)
  const rentalRef = useRef(null)
  const instructionRef = useRef(null)
  const instructorRef = useRef(null)
  const { x, y: scrollY } = useWindowScroll()

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  let theme = createMuiTheme({})
  theme = {
    ...theme,
    overrides: {
      MuiDialog: {
        paper: {
          margin: '0',
        },
        paperScrollPaper: {
          maxHeight: '100vh',
        },
      },
      MuiPaper: {
        root: {
          backgroundColor: localTheme.colors.secondaryBlack,
        },
      },
    },
  }

  const heroBannerContentQuery = query.hero_banner.document.data

  const menuQuery = query.menu_items.document.data

  console.log(open)

  return (
    <>
    <Wrapper open={open}>
      <ThemeProvider theme={theme}>
        <HeroContainer>
          <ImageHeroContainer></ImageHeroContainer>
          <Desktop>
            <Menu query={menuQuery} locale={locale} scrollY={scrollY} location={location}/>
          </Desktop>
          <Mobile>
            {' '}
            <div onClick={handleClickOpen}>
              <MenuText as="h4" type="h4" className="Menu-Hero">
                MENU
              </MenuText>
            </div>
          </Mobile>
          <Logo
            src={
              'https://images.prismic.io/skischoolrokytnice/7841b1ed-c776-4287-8ad1-f65944022efa_Untitled-2.png?auto=compress,format'
            }
          />
          <SponsorLogoContainer
            fluid={sponsorLogoQuery?.localFile?.childImageSharp?.fluid}
          ></SponsorLogoContainer>
          <HeroContent>
            <Desktop>
              <Fade bottom distance="25px">
                <HeroTitle type="h1" as="h1">
                  {heroBannerContentQuery.title.text}
                </HeroTitle>

                <HeroBody type="h3" as="h3">
                  {heroBannerContentQuery.subtitle.text}
                </HeroBody>

                <Button
                  buttonStyle="primary"
                  href={locale === 'cs-cz' ? '#intro' : '#introduction'}
                  className="btn-hero btn-1e"
                >
                  {mobileSpecificHero?.main_button_content?.text}
                </Button>
              </Fade>
            </Desktop>
            <Mobile>
              <HeroTitle
                type="h1"
                as="h1"
                style={{
                  fontSize: '48px',
                }}
              >
                {mobileSpecificHero.mobile_specific_hero_main_title.text}
              </HeroTitle>

              <HeroTitle
                type="h3"
                style={{
                  fontSize: '24px',
                }}
              >
                {mobileSpecificHero.mobile_specific_hero_subtitle.text}
              </HeroTitle>

              <HeroBody style={{ fontSize: '18px' }}>
                {heroBannerContentQuery.subtitle.text}
              </HeroBody>
            </Mobile>
          </HeroContent>

        </HeroContainer>
        <Fade bottom distance="20px">
          <IntroContainer query={query} ref={introRef} locale={locale} />
        </Fade>
        <Fade bottom distance="20px">
          <OrganizationContainer
            query={secondaryHero}
            contentQuery={query}
            ref={organizaceRef}
            locale={locale}
          />
        </Fade>
        <LargeDesktopUp>
          <Fade bottom distance="20px">
            <PricingContainer
              query={query}
              pricingQuery={pricingQuery}
              ref={cenikRef}
              locale={locale}
            />
          </Fade>
        </LargeDesktopUp>
        <LargeDesktopDown>
          <Fade bottom distance="20px">
            <MobilePricing
              query={query}
              pricingQuery={pricingQuery}
              ref={cenikRef}
              locale={locale}
            />
          </Fade>
        </LargeDesktopDown>

        <FullWidthImageDesktopPrimary></FullWidthImageDesktopPrimary>
        <Fade bottom distance="20px">
          <ReservationContainer
            query={query}
            ref={rezervaceRef}
            locale={locale}
          />
        </Fade>
        <FullWidthImageDesktopSecondary></FullWidthImageDesktopSecondary>

        <InstructionContainer query={query} ref={instructionRef} />

        <Fade bottom distance="20px">
          <RentalContainer query={query} ref={rentalRef} locale={locale} />
        </Fade>
        <Fade bottom distance="20px">
          <InstructorsContainer
            query={query}
            ref={instructorRef}
            locale={locale}
          />
        </Fade>

        <ContactFooter
          query={query}
          tertiaryHero={tertiaryHero}
          ref={kontaktRef}
          locale={locale}
          map={map}
        />

        <Footer />
        <div>
          <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
          >
            <DialogTitle
              id="customized-dialog-title"
              onClose={handleClose}
            ></DialogTitle>
            <DialogContent>
              <FlyoutMenu
                onClose={handleClose}
                query={menuQuery}
                locale={locale}
                location={location}
              />
            </DialogContent>
          </Dialog>
        </div>
        <ScrollToTop smooth />
      </ThemeProvider>
      </Wrapper>
    </>
  )
}

export default IndexLayout
