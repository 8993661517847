import styled from '@emotion/styled'
import Title from 'components/Utilities/Title'
import Text from 'components/Utilities/Text'


export const InstructionContainerMain = styled.div`
display: flex;
width: 100%;
justify-content: center;
position: relative;
padding: 40px 20px;
max-width: 800px;
margin: 0px auto;

@media(min-width: ${props => props.theme.breakpoints.md}) {
  padding: 40px 200px;
  max-width: 1440px;
}

.AnimatedLine__Dark {
background-color: #1B1725;
}
`


export const SecondaryTitle = styled(Title)`
font-weight: bold;
text-align: left;

@media(min-width: ${props => props.theme.breakpoints.md}) {
  margin-top: 30px;
}
`

export const MainBody = styled.div`

p {
  font-weight: 400;
  letter-spacing: 0.575px;
  font-size: ${props => props.theme.fonts.xxxs_large};
  font-family: ${props => props.theme.fontFamily.primary};
  color: ${props => props.theme.colors.primaryBlack};
  margin-bottom: 0px;
  text-align: left;
  position: relative;
  margin-bottom: 22px;

  @media(min-width: ${props => props.theme.breakpoints.md}) {
    font-size: ${props => props.theme.fonts.xxxs_large};
  }

  
}

margin-top: 30px;


@media(min-width: ${props => props.theme.breakpoints.md}) {
  margin-top: 40px;
}

`

export const TitleDeco = styled.div`
position: relative;
`


export const MainContentContainer = styled.div`
display: block;
text-align: center;
width: 100%;



@media(min-width: ${props => props.theme.breakpoints.md}) {
  max-width: 800px;
}

@media(min-width: ${props => props.theme.breakpoints.xl}) {
  max-width: unset;
}

`
