import React from 'react'

export const Skier = ({ className }) => {
  return (
    <svg
      width="20"
      height="35"
      viewBox="0 0 20 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect width="20" height="34.2222" fill="url(#pattern0)" />
      <defs>
        <pattern
          id="pattern0"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image0"
            transform="translate(-0.873288 -0.0978044) scale(0.00342466 0.00199601)"
          />
        </pattern>
        <image
          id="image0"
          width="800"
          height="600"
         
        />
      </defs>
    </svg>
  )
}
