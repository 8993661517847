import React from 'react'

export const Hamburger = ({className}) => {
  return (
    <svg
      width="36"
      height="19"
      viewBox="0 0 36 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : ''}
    >
      <line
        x1="0.650269"
        y1="0.985931"
        x2="35.865"
        y2="0.985931"
        stroke="#1C2C3E"
        strokeOpacity="0.47"
      />
      <line
        x1="0.650269"
        y1="9.5"
        x2="35.865"
        y2="9.5"
        stroke="#1C2C3E"
        strokeOpacity="0.47"
      />
      <line
        x1="0.650269"
        y1="18.5"
        x2="35.865"
        y2="18.5"
        stroke="#1C2C3E"
        strokeOpacity="0.47"
      />
    </svg>
  )
}
