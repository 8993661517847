import styled from '@emotion/styled'
import Title from 'components/Utilities/Title'

export const FlyoutMenuContainer = styled.div`
display: flex;
flex-direction: column;
width: 100%;
height: 100%;
position: relative;

a {
  text-decoration: none;
}

.AnimatedLine__Flyout {
  bottom: 0px;
  left:0px;
  transform: none;
  background-color: ${props => props.theme.colors.primaryGreen};
}
`

export const MenuItem = styled(Title)`
font-weight: 400;
margin-top: 24px;
margin-bottom: 10px;
cursor: pointer;
color: white;
position: relative;
text-shadow: 1px 1px 14px black;



`


export const Logo =styled.img`
width: 70px;
position: absolute;
bottom: 15px;
left: 0px;

`

