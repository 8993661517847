import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import AnimatedLine from 'components/AnimatedLine'
import {
  RentalContainerMain,
  MainContentContainer,
  SecondaryTitle,
  MainBody,
  TitleDeco,
  StyledImage,
  PhoneStyled,
  TextContent,
  EmailStyled,
  MainBodyHTML,
} from './style'

const RentalContainer = (props, ref) => {
  console.log('props', props)
  const locale = props.locale

  const query = props.query
  function createMarkup() {
    return { __html: query?.rental_notes?.html }
  }

  console.log(locale)
  return (
    <RentalContainerMain
      ref={ref}
      id={locale === 'cs-cz' ? 'pujcovna' : 'rental'}
    >
      <MainContentContainer>
        <TitleDeco>
          <SecondaryTitle type="h3" as="h3" uppercase>
            {query?.rental_title?.text}
          </SecondaryTitle>
          <AnimatedLine className="AnimatedLine__ProjectExcerpt" />
        </TitleDeco>
        {/* <TextContent> */}
        <MainBody type="primary">{query?.rental_body?.text}</MainBody>

        <StyledImage
          fluid={query?.rental_image?.localFile?.childImageSharp?.fluid}
        ></StyledImage>

        <MainBodyHTML
          dangerouslySetInnerHTML={createMarkup()}
          type="primary"
        ></MainBodyHTML>
        <EmailStyled type="primary">
          <a href={`mailto:${query?.rental_email?.text}`}>
            {query?.rental_email?.text}
          </a>{' '}
        </EmailStyled>
        <PhoneStyled type="primary">
          {' '}
          <a href={`tel:${query?.rental_contact_tel_1?.text}`}>
            {query?.rental_contact_tel_1?.text}
          </a>
        </PhoneStyled>
        <PhoneStyled type="primary">
          {' '}
          <a href={`tel:${query?.rental_contact_tel_2?.text}`}>
            {query?.rental_contact_tel_2?.text}
          </a>
        </PhoneStyled>

        {/* </TextContent> */}
      </MainContentContainer>
    </RentalContainerMain>
  )
}

RentalContainer.propTypes = {}

export default forwardRef(RentalContainer)
