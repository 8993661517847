import React, { useState } from 'react';
import { LineContainer, LineContent } from './style.js';
import IntersectionVisible from 'react-intersection-visible';


const AnimatedLine = ({ children, className, heightOffsetSecondary, shortLine,noPrimaryTitle, heightOffset }) => {
  const [isVisible, setIsVisible] = useState(false);


  const lineContentProps = {
    shortLine: shortLine ? shortLine : null, 
    className: className ? className : "",
  }

  const lineContainerProps = {
    shortLine: shortLine ? shortLine : null, 
    heightOffset: heightOffset ? heightOffset : null,
    heightOffsetSecondary: heightOffsetSecondary ? heightOffsetSecondary : null, 
    className: className ? className : "",
    noPrimaryTitle: noPrimaryTitle ? noPrimaryTitle : null, 
  }
  return (
    <IntersectionVisible
      onShow={() => setIsVisible(true)}
      onHide={() => setIsVisible(false)}
    >
      <LineContainer {...lineContainerProps} isVisible={isVisible} >
        <LineContent {...lineContentProps} isVisible={isVisible}>{children}</LineContent>
      </LineContainer>
    </IntersectionVisible>
  );
};

export default AnimatedLine;
