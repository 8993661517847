
import styled from '@emotion/styled'
import Title from 'components/Utilities/Title'
import Text from 'components/Utilities/Text'
import BackgroundImage from 'gatsby-background-image'

export const InstructorsContainerStyled = styled.div`
  width: 100%;
  position: relative;
  padding: 40px 20px;
  margin: 0px auto;
  max-width: 800px;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    padding: 40px 200px;
    max-width: 1440px;
  }

  .AnimatedLine__Dark {
    background-color: #1B1725;
  }
`

export const MainContentContainer = styled.div`
  text-align: left;
  width: 100%;
  margin: 0 auto;

  @media(min-width: ${props => props.theme.breakpoints.md}) {
    max-width: 800px;
  }
  
  @media(min-width: ${props => props.theme.breakpoints.xl}) {
    max-width: unset;
  }
`

export const SecondaryTitle = styled(Title)`
  font-weight: bold;
  margin-top: 30px;
  text-align: left;
`

export const TitleDeco = styled.div`
  position: relative;
`

export const MainBody = styled(Text)`
display: block;
letter-spacing: 0.575px;
text-align: left;
width: 100%;
position: relative;
margin: 30px auto;
font-weight: 400;

@media(min-width: ${props => props.theme.breakpoints.md}) {
  // max-width: 600px;
  // padding-top: 40px;
  // padding-bottom: 40px;
  margin-top: 40px;
}


@media(min-width: ${props => props.theme.breakpoints.l}) {
  // max-width: 800px;
}

  p {
    a {
      text-decoration: none;
      color: ${props => props.theme.colors.secondaryBlack};
      font-style: italic;
    }
  }
`

export const MainBodyShort = styled(Text)`
font-weight: 400;
letter-spacing: 0.575px;
// width: 600px;
text-align: left;
// margin: 0 auto;
// margin-top: 30px;

@media (min-width: ${props => props.theme.breakpoints.md}) {
  // width: 800px;
  padding-bottom: 40px;
  padding-right: 150px;
  position: relative;
  margin: 0 auto;
  margin-top: 0px;
}

p {
  a {
    text-decoration: none;
    color: ${props => props.theme.colors.secondaryBlack};
    font-style: italic;
  }
}

`