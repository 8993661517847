import styled from '@emotion/styled'
import Title from 'components/Utilities/Title'

export const MenuContainerMain = styled.div`
display: flex;
width: 100%;
justify-content: center;
align-items: center;
margin: 0 auto;
z-index: 1000;
position: fixed;
top: 0;
left: 50%;
opacity: 1;
transform: translate(-50%, 0%);
background-color: ${props => props.scrollY > 80 ? props.theme.colors.white : 'none'};
transition: background-color .7s, opacity .7s;

a {
  text-decoration: none;
}

svg {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 15px;
  right: 1em;
  cursor: pointer;
}

`

export const MenuItem = styled(Title)`
font-weight: 400;
margin: ${props => props.scrollY > 50 ? '18px 18px 9px 18px' : '18px 18px 0px 18px'}; 
cursor: pointer;
text-decoration: none;
transition: font-size .7s ease, text-decoration .7s ease;


&:hover {
  color: black!important;
  text-decoration: underline;
}

@media(min-width: ${props => props.theme.breakpoints.md}) {
  font-size: ${props => props.scrollY > 50 ? '16px!important' : '14px!important'};
}

@media(min-width: ${props => props.theme.breakpoints.l}) {
  font-size: ${props => props.scrollY > 50 ? '18px!important' : '16px!important'};
}

`

export const Logo =styled.img`
width: ${props => props.scrollY > 50 ? '70px' : '50px'};
position: absolute;
top: ${props => props.scrollY > 50 ? '10px' : '20px'};
left: 2%;
transition: width .7s, top .7s;

// @media(min-width: ${props => props.theme.breakpoints.l}) {
//   width: ${props => props.scrollY > 50 ? '90px' : '70px'};
// }

`

