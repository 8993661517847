import React from 'react'
import PropTypes from 'prop-types'
import { MenuContainerMain, MenuItem, Logo } from './style'
import { Link } from 'gatsby'
import LocaleComponent from "./../LanguageSwitcher"

const Menu = ({ query, locale, scrollY, location }) => {
  return (
    <MenuContainerMain scrollY={scrollY}>
       <Logo src={'https://images.prismic.io/skischoolrokytnice/7841b1ed-c776-4287-8ad1-f65944022efa_Untitled-2.png?auto=compress,format'} scrollY={scrollY}/>
      {query.menu_items.map((item, index) => {
        return (
          <Link
          exact="true" to={
              locale === 'cs-cz'
                ? `/${item.menu_item_uid.text}`
                : `/en/${item.menu_item_uid.text}`
            }
            activeClassName="active"
          >
            <MenuItem  scrollY={scrollY} type="h5-secondary" uppercase={true} activeClassName="active">
              {item.menu_item_label.text}
            </MenuItem>
          </Link>
        )
      })}
      <LocaleComponent location={location}/>
    </MenuContainerMain>
  )
}

Menu.propTypes = {}

export default Menu
