import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TranslateRevealStyled } from './styles';
import gsap from 'gsap';
import IntersectionVisible from 'react-intersection-visible';

const reveal = (element) => {
  const chars = element.querySelectorAll(
    '.content__paragraph .word > .char, .whitespace'
  );

  gsap.to('.content__paragraph', 1, {
    opacity: 1,
  });

  gsap.to(chars, 1, {
    opacity: 1,
    y: 0,
    ease: 'power2.inOut',
    stagger: {
      each: 0.1,
      amount: 0.3,
    },
  });
};

const TranslateReveal = ({
  children,
  animated,
  wordRef,
  animationDelay = 0,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (animated && wordRef?.current && isVisible) {
      setTimeout(() => {
        reveal(wordRef.current);
      }, animationDelay);
    }
  }, [wordRef?.current, animated, isVisible]);

  return (
    <TranslateRevealStyled>
      <IntersectionVisible onShow={() => setIsVisible(true)}>
        {children}
      </IntersectionVisible>
    </TranslateRevealStyled>
  );
};

TranslateReveal.propTypes = {
  children: PropTypes.node.isRequired,
  wordRef: PropTypes.any,
  animated: PropTypes.bool,
};

export default TranslateReveal;
