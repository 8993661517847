import styled from '@emotion/styled'
import Title from 'components/Utilities/Title'
import Text from 'components/Utilities/Text'
import Image from 'gatsby-image'

export const OrganizationHeroContainer = styled.div`
  max-height: 1000px;
  width: 100%;
  background-image: url('https://images.prismic.io/skischoolrokytnice/cc009df8-93fb-4e00-92e9-4fa5ed7a54cc_DSC_2389.jpg?auto=compress,format');
  // background-attachment: fixed;
  background-size: cover;
  background-position: 50% 50%;
  display: flex;
  align-items: center;
  flex-direction: column;



  @media (min-width: ${props => props.theme.breakpoints.md}) {
    height: auto;
    max-height: unset;
    margin-bottom: 100px;
    padding-bottom: 0;
    padding-top: 40px;
    padding-right: 0px;
    padding-left: 0px;
  }
`
export const CardImage = styled(Image)`
  width: 300px;
  margin: 30px auto;
  border-radius: 5px;
  box-shadow: 0px 8px 24px rgba(0,0,0,0.15);
  display: none;

  @media (min-width: ${props => props.theme.breakpoints.xxl}) {
      display: block;
      width: 300px;
  }
`

export const CardContent = styled(Text)`
  background-color: white;
  letter-spacing: 0.575px;
  opacity: 0.7;
  color: ${props => props.theme.colors.secondaryBlack};
  padding: 40px 20px;
  max-width: 500px;
  margin: 0 auto;
  border-radius: 5px;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
  font-size: ${props => props.theme.fonts.xxxs_small};

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    max-width: unset;
    font-size: ${props => props.theme.fonts.xxxs_large};
  }
  @media (min-width: ${props => props.theme.breakpoints.xxl}) {
    max-width: 300px;
    height: 400px;
  }
`

export const CardContainerMain = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  margin-bottom: 40px;

  @media (min-width: ${props => props.theme.breakpoints.l}) {
    flex-direction: column;
    align-items: baseline;
  }

  @media (min-width: ${props => props.theme.breakpoints.xxl}) {
    max-width: 400px;
    align-items: baseline;
  }
`

export const AllCardStyled = styled.div`
  padding-top: 40px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    align-items: flex-start;
  }

  @media (min-width: ${props => props.theme.breakpoints.xxl}) {
    justify-content: space-between;
    flex-direction: row;
    position: relative;
    padding-top: 40px;
  }
`

export const OrganizationTitle = styled(Title)`
  font-weight: bold;
  margin-top: 0px;
  width: 100%;
  text-align: left;

  @media (min-width: ${props => props.theme.breakpoints.xxl}) {
    margin-top: 40px;
  }
`

export const TitleDeco = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
  // max-width: 800px;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    // max-width: unset;
  }
`

export const Content = styled.div` 
width: 100%;
max-width: 800px;
padding: 40px 20px;

@media (min-width: ${props => props.theme.breakpoints.md})  {
  max-width: 1440px;
  padding-right: 200px;
  padding-left: 200px;

}
`