import styled from '@emotion/styled'
import Title from 'components/Utilities/Title'
import Text from 'components/Utilities/Text'

export const IntroContainerMain = styled.div`
display: flex;
width: 100%;
max-width: 800px;
justify-content: center;
position: relative;
margin: 40px auto;
padding: 0px 20px;

@media(min-width: ${props => props.theme.breakpoints.md}) {
  margin: 100px auto 20px;
  padding: unset;
  max-width: 1440px;

}


`

export const MainContentContainer = styled.div`
display: block;
text-align: left;
width: 100%;
max-width: 800px;

@media(min-width: ${props => props.theme.breakpoints.md}) {
  padding-left: 200px;
  padding-right: 200px;
  max-width: unset;
}


// @media(min-width: ${props => props.theme.breakpoints.xl}) {

// }

`

export const MainTitle = styled(Title)`
font-weight: 100;
margin-bottom: 30px;
font-weight: bold;

@media(min-width: ${props => props.theme.breakpoints.md}) {
  margin-bottom: 40px;
}

`

export const MainBody = styled(Text)`
font-weight: 400;
letter-spacing: 0.575px;
margin-bottom: 30px;

@media(min-width: ${props => props.theme.breakpoints.md}) {
  margin-bottom: 80px;
}

`

export const TitleDeco = styled.div`
position: relative;
`

export const SecondaryTitle = styled(Title)`
margin-bottom: 30px;
font-weight: bold;

@media(min-width: ${props => props.theme.breakpoints.md}) {
  margin-bottom: 40px;
}
`


export const SecondaryBody = styled(Text)`


`