import styled from '@emotion/styled'
import { keyframes, css } from '@emotion/core'

const reveal1 = () => css`
  ${keyframes`
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  `} 1s ease-in-out forwards;
`

const reveal2 = () => css`
  ${keyframes`
    0% {
      width: 100%;
    }
    100% {
      width: 0%;
    }
  `} 1s ease-in-out forwards;
`

export const LineContainer = styled.div`
  width: 0%;
  position: absolute;
  bottom: -5px;
  left: 0%;
  transform: translate(0%, 0);
  background-color: ${props => props.theme.colors.primaryBlack};
  height: 1px;
  animation: ${props => (props.isVisible ? reveal1 : null)};



  @media (min-width: ${props => props.theme.breakpoints.md}) {
    bottom: -1px;
  }


`

export const LineContent = styled.div`
  background: ${props => props.theme.colors.white};
  height: 2px;
  position: absolute;
  right: 0;
  bottom: 0;
`
