import styled from '@emotion/styled'
import { theme } from 'styles'

export const TitleStyled = styled.span`
  font-family: ${theme.fontFamily.primary};
  text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
  color: ${({ whiteTitle }) =>
    whiteTitle ? theme.colors.white : theme.colors.primaryBlack};
  position: relative;
  margin-bottom: 0px;
  display: block;
  font-weight: normal;
  letter-spacing: 0.875px;

  &.Title__displayNone {
    display: none;
  }

  ${({ type }) => {
    switch (type) {
      case 'h1':
        return `
        font-size: ${theme.fonts.l};
        `
      case 'h1-secondary':
        return `
            font-size: ${theme.fonts.xxxs_medium};
          `

      case 'h2':
        return `
          font-size: ${theme.fonts.m};
          `
      case 'h3':
        return `
            font-size: ${theme.fonts.l};
            line-height: 1.1;
          `
      case 'h4':
        return `
            font-size: ${theme.fonts.xxs};
          `
      case 'h5':
        return `
        font-size: ${theme.fonts.s};
        `
      case 'h5-secondary':
        return `
          font-size: ${theme.fonts.xxs};
          `
      default:
        //
        // Default Font Style
        //
        return `
            font-size: ${theme.fonts.xl_medium};
        `
    }
  }};

  @media (min-width: ${theme.breakpoints.md}) {
    ${({ type }) => {
      switch (type) {
        case 'h1':
          return `
            font-size: ${theme.fonts.xxxl_medium};
          `
        case 'h1-secondary':
          return `
            font-size: ${theme.fonts.l_secondary};
          `

        case 'h2':
          return `
            font-size: ${theme.fonts.xl}; 
          `
        case 'h3':
          return `
            font-size: ${theme.fonts.xl}; 
          `
        case 'h4':
          return `
            font-size: ${theme.fonts.xl_large}; 
          `
        case 'h5':
          return `
            font-size: ${theme.fonts.l};
            `
        case 'h5-secondary':
          return `
                font-size: ${theme.fonts.xxs};
                `
        default:
          //
          // Default Font Style
          //
          return `
            font-size: ${theme.fonts.xxl_large}; 
          `
      }
    }};
  }
`
